.salary-calculator {
  --primary: #f78f1e;
  --secondary: #69321f;
  --gray: #787878;
  --light-gray: #eeeeee;
  --light: #f5f7ec;
}

.salary-calculator.nbov {
  --primary: #ff6600;
  --secondary: #6b6b7d;
}

.salary-calculator.fnv {
  --primary: #0099dc;
  --secondary: #62a316;
}

.salary-calculator.nvb {
  --primary: #5e3419;
  --secondary: #a35018;
}

.salary-calculator.cnv {
  --primary: #692f8c;
  --secondary: #004e9b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f7ec;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
